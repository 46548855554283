<template>
  <div class="layout-wrapper layout-static" :class="[{ 'layout-static-inactive': is_static && !isMobile() }, { 'layout-mobile-active': is_static && isMobile() }]">
    <template v-if="loading"> </template>
    <template v-else>
      <topbar v-model:is_static="is_static" />
      <sidebar @click="isStatic()" />
      <container />
    </template>
    <div v-if="is_static && isMobile()" class="layout-mask" @click="is_static = !is_static"></div>
  </div>

  <Toast />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Topbar from "./Topbar.vue";
import Sidebar from "./Sidebar.vue";
import Container from "./Container.vue";

export default {
  name: "Layout",
  components: { Topbar, Sidebar, Container },
  data() {
    return {
      loading: true,
      is_static: false,
    };
  },
  computed: { ...mapGetters(["server_error", "profile"]) },
  watch: {
    server_error: {
      handler() {
        let message = "";
        if (this.server_error && this.server_error.response) {
          if (this.server_error.response.status == 401) {
            this.clearLocalStorage();
            this.$router.push("/auth");
          } else if (this.server_error.response.status == 404) message = this.$t("not_found");
          else if (this.server_error.response.status == 422) message = this.$t("validation_error");
          else if (this.server_error.response.status == 403) message = this.$t("forbidden");
          else if (this.server_error.response.status == 500) message = this.$t("server_error");
          else message = this.$t("unknown_error");
          this.$toast.add({ summary: this.$t(message), severity: "error", /*detail: this.$t(this.server_error.response.data.message),*/ life: 3000 });
        }
        setTimeout(() => {
          this.$store.commit("setServerError", null);
        }, 100);
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(["fetchProfile", "clearLocalStorage", "fetchActiveLanguage"]),
    getSettings() {
      let profile = JSON.parse(localStorage.getItem("profile")) ?? {};
      this.$store.commit("setUserRoles", profile?.roles);
      this.$store.commit("setUserPermissions", profile?.permissions);
      this.loading = false;
      if (!localStorage.getItem("act_languages")) this.$store.dispatch("fetchActiveLanguage");
    },
    isMobile() {
      return window.innerWidth <= 991 ? true : false;
    },
    isStatic() {
      if (this.isMobile()) this.is_static = !this.is_static;
    },
  },
  mounted() {
    if (!localStorage.getItem("profile")) {
      this.fetchProfile().then(() => {
        this.loading = false;
      });
    } else {
      this.getSettings();
    }
    this.$nextTick(() => {
      window.addEventListener("resize", this.isMobile);
    });
  },
};
</script>
