import { createRouter, createWebHistory } from "vue-router";
import Layout from "../views/layout";

const routes = [
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("../views/Home.vue"),
      },
      {
        path: "/user",
        name: "user",
        component: () => import("../views/pages/user"),
      },
      {
        path: "region",
        name: "region",
        component: () => import("../views/pages/region"),
      },
      {
        path: "dealer",
        name: "dealer",
        component: () => import("../views/pages/dealer"),
      },
      {
        path: "order-type",
        name: "order_type",
        component: () => import("../views/pages/orderType"),
      },
      {
        path: "department",
        name: "department",
        component: () => import("../views/pages/department"),
      },
      {
        path: "transport-type",
        name: "transport_type",
        component: () => import("../views/pages/transportType"),
      },
      {
        path: "spare-part-stock",
        name: "spare_part_stock",
        component: () => import("../views/pages/sparePart/Stock"),
      },
      {
        path: "spare-part",
        name: "spare_part",
        component: () => import("../views/pages/sparePart"),
      },
      {
        path: "spare-part-category",
        name: "spare_part_category",
        component: () => import("../views/pages/sparePartCategory"),
      },
      {
        path: "unit",
        name: "unit",
        component: () => import("../views/pages/unit"),
      },
      {
        path: "consumpt-document",
        name: "consumpt_document",
        component: () => import("../views/pages/consumptDocument"),
        children: [
          {
            path: "",
            name: "consumpt_document_list",
            component: () => import("../views/pages/consumptDocument/ConsumptDocumentList.vue"),
          },
          {
            path: "create",
            name: "consumpt_document_create",
            component: () => import("../views/pages/consumptDocument/Upsert.vue"),
          },
          {
            path: "edit/:id",
            name: "consumpt_document_edit",
            component: () => import("../views/pages/consumptDocument/Upsert.vue"),
          },
        ],
      },
      {
        path: "receipt-document",
        name: "receipt_document",
        component: () => import("../views/pages/receiptDocument"),
        children: [
          {
            path: "",
            name: "receipt_document_list",
            component: () => import("../views/pages/receiptDocument/ReceiptDocumentList.vue"),
          },
          {
            path: "create",
            name: "receipt_document_create",
            component: () => import("../views/pages/receiptDocument/Upsert.vue"),
          },
          {
            path: "edit/:id",
            name: "receipt_document_edit",
            component: () => import("../views/pages/receiptDocument/Upsert.vue"),
          },
        ],
      },
      {
        path: "role",
        name: "role",
        component: () => import("../views/pages/role"),
      },
      {
        path: "fault-act",
        name: "fault_act",
        component: () => import("../views/pages/faultAct"),
      },
      {
        path: "fault-type",
        name: "fault_type",
        component: () => import("../views/pages/faultType"),
      },
      {
        path: "waybill",
        name: "waybill",
        component: () => import("../views/pages/waybill"),
        children: [
          {
            path: "",
            name: "waybill_list",
            component: () => import("../views/pages/waybill/WaybillList.vue"),
          },
          {
            path: "create",
            name: "waybill_create",
            component: () => import("../views/pages/waybill/Upsert.vue"),
          },
          {
            path: "edit/:id",
            name: "waybill_edit",
            component: () => import("../views/pages/waybill/Upsert.vue"),
          },
          {
            path: "taxirovka/:id",
            name: "waybill_taxirovka",
            component: () => import("../views/pages/waybill/Taxirovka.vue"),
          }
        ],
      },
      {
        path: "waybill-invoice",
        name: "waybill_invoice",
        component: () => import("../views/pages/reports/WaybillInvoice.vue"),
      },
      {
        path: 'transport-report',
        name: 'transport_report',
        component: () => import('../views/pages/reports/TransportReport.vue')
      },
      {
        path: "waybill-type",
        name: "waybill_type",
        component: () => import("../views/pages/waybillType"),
      },
      {
        path: "route",
        name: "route",
        component: () => import("../views/pages/route"),
      },
      {
        path: "driver",
        name: "driver",
        component: () => import("../views/pages/driver"),
      },
      {
        path: "transport",
        name: "transport",
        component: () => import("../views/pages/transport"),
      },
      {
        path: "order",
        name: "order",
        component: () => import("../views/pages/order"),
      },
      {
        path: "fuel",
        name: "fuel",
        component: () => import("../views/pages/fuel"),
      },
      {
        path: "fuel-station",
        name: "fuel_station",
        component: () => import("../views/pages/fuelStation"),
      },
    ],
  },
  {
    path: "/auth",
    name: "Auth",
    component: () => import("../views/login"),
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("@/views/layout/404"),
    meta: {
      requiresAuth: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
